import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { SessionKey, SessionStorageService } from '../services/session-storage.service';

type PrivateRouteProps = {
    path?: string,
    exact?: boolean
}

export default class PrivateRoute extends React.Component<PrivateRouteProps, {}> {
    private storageSession: SessionStorageService = new SessionStorageService();

    public render() {
        return (
            <Route exact={this.props.exact} path={this.props.path}>
                {
                    this.storageSession.getItem(SessionKey.ID_TOKEN) !== '' &&
                    this.props.children
                }
                {this.storageSession.getItem(SessionKey.ID_TOKEN) === '' &&
                    <Redirect exact from="/" to="login" />
                }
            </Route>
        )
    }
}
