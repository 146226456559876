import { AxiosResponse } from 'axios';
import { FinalizeRequestBody, FinalizeResponse, VerifyRequestBody, VerifyResponseKo, VerifyResponseOk } from '../models/eng.model';
import API from './api-service';
import { SessionKey, SessionStorageService } from './session-storage.service';


export class EngService {
    private sessionService: SessionStorageService = new SessionStorageService();

    async verify(CF: string, PIN: string): Promise<AxiosResponse<VerifyResponseOk | VerifyResponseKo>> {// Promise<AxiosResponse<VerifyResponseOk|VerifyResponseKo>>{
        const payload: VerifyRequestBody = { cf_studente: CF.toUpperCase(), pin: PIN };
        const token = this.sessionService.getItem(SessionKey.ID_TOKEN);
        return API.post<VerifyResponseOk | VerifyResponseKo>('/verifica', payload, {
            headers: { 'X-id-token': `${token}`, 'x-api-key': 'm3PgsSJx0K91myZYdAf1f2c2o9CqpX532cOMUZYJ' }
        });
    }

    async finalize(CF: string, PIN: string, name: string, surname: string, description: string,
        company: string, origin: string, origin_desc: string, destination: string, destination_desc: string, price: number): Promise<AxiosResponse<FinalizeResponse>> {
        const payload: FinalizeRequestBody = {
            cf_studente: CF.toUpperCase(),
            pin: PIN,
            nome_studente: name,
            cognome_studente: surname,
            descrizione: description,
            azienda: company,
            origine_abbonamento: origin,
            desc_origine_abbonamento: origin_desc,
            destinazione_abbonamento: destination,
            desc_destinazione_abbonamento: destination_desc,
            prezzo_abbonamento: price
        };
        const token = this.sessionService.getItem(SessionKey.ID_TOKEN);
        return API.post<FinalizeResponse>('/finalizza', payload, {
            headers: { 'X-id-token': `${token}`, 'x-api-key': 'm3PgsSJx0K91myZYdAf1f2c2o9CqpX532cOMUZYJ' }
        });
    }

    async downloadStoredData(): Promise<AxiosResponse<any>> {
        const token = this.sessionService.getItem(SessionKey.ID_TOKEN);
        return API.get<any>('/subscriptions', {
            headers: { 'X-id-token': `${token}`, 'x-api-key': 'm3PgsSJx0K91myZYdAf1f2c2o9CqpX532cOMUZYJ' }
        });
    }


}
