import { AxiosResponse } from 'axios';
import { LoginResponse } from '../models/login.response.model';
import API from './api-service';
import { SessionKey, SessionStorageService } from './session-storage.service';


export class AuthService {
    private sessionService: SessionStorageService = new SessionStorageService();

    async validateLoginData(username: string, password: string): Promise<AxiosResponse<LoginResponse>> {
        const payload = { username, password };

        return API.post<LoginResponse>('/auth/login', payload, {
            headers: {
                'x-api-key': 'm3PgsSJx0K91myZYdAf1f2c2o9CqpX532cOMUZYJ'
            }
        });
    }

    async changePassword(oldPassword: string, newPassword: string): Promise<AxiosResponse<LoginResponse>> {
        const username = this.sessionService.getItem(SessionKey.USERNAME);
        const payload = { username, oldPassword, newPassword };

        return API.post<LoginResponse>('/auth/firstLogin', payload, {
            headers: {
                'x-api-key': 'm3PgsSJx0K91myZYdAf1f2c2o9CqpX532cOMUZYJ'
            }
        });
    }

    logout() {
        this.sessionService.removeItem(SessionKey.ID_TOKEN);
        this.sessionService.removeItem(SessionKey.REFRESH_TOKEN);
    }

}
