import './modal.scss';
import React from 'react';
import Button from "@material-ui/core/Button";


type  ModalProps = {
  show: boolean;
  children: any;
  handleClose: () => void;
  secondaryButton: boolean;
  secondaryButtonText?: string;
  secondaryButtonHandler?: () => void;
}

export default class Modal extends React.Component<ModalProps, {}> {

    public render() {  
        const { handleClose, show, children, secondaryButton, secondaryButtonText, secondaryButtonHandler }= this.props;
        const showHideClassName = show ? "modal display-block" : "modal display-none";
        
        return (
            <div className={showHideClassName}>
                <section className="modal-main">
                    {children}
                    <div className="modal-button-container">
                        <Button className="modal-button" variant="contained" color="primary" onClick={handleClose}>
                            chiudi
                        </Button>
                        {secondaryButton && 
                        <Button className="modal-button" variant="contained" color="primary" onClick={secondaryButtonHandler}>
                            {secondaryButtonText}
                        </Button>
                    }
                    </div>
                </section>
            </div>
        );
    }
}
