import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import loading from '../../assets/loader.gif';
import logo from '../../assets/nobg.png';
import './login.scss';

interface LoginProps {
  verifingAuthorization: boolean;
  authError: boolean;
  authErrorMessage: string;
  login: () => void;
  changedUsername: (username: string) => void;
  changedPassword: (password: string) => void;
}

class Login extends React.Component<
  LoginProps
  > {

  public render() {
    const { 
      login, 
      changedUsername, 
      changedPassword, 
      authError,
      authErrorMessage, 
      verifingAuthorization, 
    } = this.props;

    return (
      <div className="login-content">
        <header className="login-title">
          <img className="login-logo" src={logo} alt="Logo" />
        </header>
        <form className="area-form">
          <div className="login-field">
            <TextField
              error={authError}
              helperText=""
              id="username-field"
              label="Username"
              autoComplete="current-username"
              variant="outlined"
              onChange={(e) => changedUsername(e.target.value)}
              required
            />
          </div>
          <div className="login-field">
            <TextField
              error={authError}
              helperText={authError && authErrorMessage}
              id="password-field"
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              onChange={(e) => changedPassword(e.target.value)}
              required
            />
          </div>
            <Button variant="contained" color="primary" onClick={login}>
              Login
            </Button>
            {verifingAuthorization &&
              <div className="overlay">
                <img className='loading-gif' src={loading} alt='loadingGif' />
              </div>
            }
        </form>
      </div>
    );
  }
}

export default Login;
