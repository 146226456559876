import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import loading from '../../assets/loader.gif';
import './modify-password.scss';

interface LoginProps {
  verifingAuthorization: boolean;
  modifyPasswordError: boolean;
  modifyPasswordErrorMessage: string;
  changedOldPassword: (password: string) => void;
  changedNewPassword: (password: string) => void;
  changedRepeatedNewPassword: (password: string) => void;
  modifyPassword: () => void;
}

class ModifyPassword extends React.Component<
  LoginProps
  > {

  public render() {
    const { 
      changedOldPassword, 
      changedNewPassword, 
      changedRepeatedNewPassword,
      modifyPassword,
      modifyPasswordError, 
      modifyPasswordErrorMessage,
      verifingAuthorization
    } = this.props;

    return (
      <div className="modify-password-content">
        <header className="modify-password-title">
          <h4>Modifica la password al primo accesso</h4>
        </header>
        <form className="area-form">
          <div className="modify-password-field first-field">
            <TextField
              error={modifyPasswordError}
              helperText=""
              id="old-password-field"
              label="Vecchia password"
              // type="password"
              variant="outlined"
              onChange={(e) => changedOldPassword(e.target.value)}
              required
            />
          </div>
          <div className="modify-password-field">
            <TextField
              error={modifyPasswordError}
              helperText=""
              id="new-password-field"
              label="Nuova password"
              // type="password"
              variant="outlined"
              onChange={(e) => changedNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="modify-password-field">
            <TextField
              error={modifyPasswordError}
              helperText={modifyPasswordError && modifyPasswordErrorMessage}
              id="repeat-new-password-field"
              label="Ripeti nuova passowrd"
              // type="password"
              variant="outlined"
              onChange={(e) => changedRepeatedNewPassword(e.target.value)}
              required
            />
          </div>
            <Button variant="contained" color="primary" onClick={modifyPassword}>
              Modifica Password
            </Button>
            {verifingAuthorization &&
              <div className="overlay">
                <img className='loading-gif' src={loading} alt='loadingGif' />
              </div>
            }
        </form> 
      </div>
    );
  }
}

export default ModifyPassword;
