import * as React from 'react';
import { EngService } from '../../services/eng-service';
import { SessionKey, SessionStorageService } from "../../services/session-storage.service";
import Home from './home';
import { VerifyResponseOk, VerifyResponseKo, FinalizeRequestBody } from '../../models/eng.model';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

interface HomeContainerState {
    verifyCF: string,
    verifyPin: string,
    verifingValidity: boolean,
    verificationError: boolean,
    verificationErrorMessage: string;
    validationResponse?: VerifyResponseOk;
    finalizing: boolean;
    finalizeError: boolean;
    finalizeErrorMessage: string;
    finalizeSuccess: boolean;
    finalizeCF: string; 
    finalizePin: string; 
    name: string; 
    surname: string; 
    description: string; 
    company: string; 
    origin: string;
    originDesc: string;
    destination: string;
    destinationDesc: string;
    price: number;
    showVerifyModal: boolean;
    showFinalizeModal: boolean;
    downloadingData: boolean;
}


class HomeContainer extends React.Component<{}, HomeContainerState>{

        
    private engService: EngService = new EngService();
    private sessionService: SessionStorageService = new SessionStorageService();
    constructor(props: any) {
        super(props);
        this.state = {
            verifyCF: '',
            verifyPin: '',
            verifingValidity: false,
            verificationError: false,
            verificationErrorMessage: '',
            finalizing: false,
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            finalizeCF: '',
            finalizePin: '',
            name: '',
            surname: '',
            description: '',
            company: '',
            origin: '',
            originDesc: '',
            destination: '',
            destinationDesc: '',
            price: -1,
            showVerifyModal: false,
            showFinalizeModal: false,
            downloadingData: false
        }
    }

    componentDidMount() {
        document.addEventListener('keypress', this.handleEnterPressed);
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleEnterPressed);
    }

    verify = () => {
        const { verifyCF, verifyPin } = this.state;
        this.setState({ verifingValidity: true, finalizeSuccess: false },
        () => {
             this.engService.verify(verifyCF, verifyPin).then((validationResponse) => {
             if (validationResponse) {
                 this.setState({verifingValidity: false }, ()=> { 
                     if(validationResponse.data.esito === "ok"){
                         const response = validationResponse.data  as VerifyResponseOk;
                         this.setState({verificationErrorMessage: '', showVerifyModal: true, validationResponse: response});
                     }
                     if(validationResponse.data.esito === "ko"){
                         const response = validationResponse.data  as VerifyResponseKo;
                         this.setState({verificationErrorMessage: response.message, verificationError: true});
                     }
                 });
             }
             }).catch((err) => {
                 this.setState({ 
                    verificationError: true, 
                    verifingValidity: false, 
                    verificationErrorMessage: err.response.status === 400 ? err.response.data.messaggio : err.response.status > 400  && err.response.status < 500 ? err.response.data.errors[0].msg : 'Errore nella verifica di CF e PIN' 
                });
             });
        });
    };

    ChangedVerifyPin = (newPin: string) => {
        this.setState({
            verifyPin: newPin,
            verificationError: false
        });
    }

    ChangedVerifyCF = (newCF: string) => {
        this.setState({
            verifyCF: newCF,
            verificationError: false
        });
    }
    
    openFinalizeModal = () => {
        
        const { 
            finalizeCF,
            finalizePin,
            price
        } = this.state;

        if(finalizeCF.length === 0){
            this.setState({ 
                finalizeError: true, finalizeErrorMessage: 'inserire un codice fiscale' });
        } else if(finalizePin.length === 0){
            this.setState({ finalizeError: true, finalizeErrorMessage: 'inserire un Pin' });
        } else if(price === -1){
            this.setState({ finalizeError: true, finalizeErrorMessage: 'inserire il prezzo dell\'abbonamento' });
        } else{
            this.setState({
                showFinalizeModal: true,
            });
        }
    }

    finalize = () =>  {
        const { 
            finalizeCF,
            finalizePin,
            name,
            surname,
            description,
            company,
            origin,
            originDesc,
            destination,
            destinationDesc,
            price
        } = this.state;
            
        this.setState({ showFinalizeModal: false, finalizing: true, finalizeSuccess: false },
        () => {
            this.engService.finalize(
                finalizeCF,
                finalizePin,
                name,
                surname,
                description,
                company,
                origin,
                originDesc,
                destination,
                destinationDesc,
                price).then((finalizeResponse) => {
                if (finalizeResponse) {
                    this.setState({finalizing: false }, ()=> { 
                        if(finalizeResponse.data != null){
                            this.setState({finalizeSuccess: true, finalizing: false});
                        }
                    });
                }
            }).catch((err) => {
                this.setState({ 
                    finalizeError: true, 
                    finalizing: false, 
                    finalizeErrorMessage: err.response.status === 400 ? err.response.data.messaggio : err.response.status > 400  && err.response.status < 500 ? err.response.data.errors[0].msg : 'Errore nella finalizzazione' 
                });
            });
        });
    }

    ChangedFinalizeCF = (newCF: string) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            finalizeCF: newCF,
        });
    }

    ChangedFinalizePin = (newPin: string) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            finalizePin: newPin,
        });
    }

    ChangedName = (newName: string) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            name: newName,
        });
    }

    ChangedSurname = (newSurname: string) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            surname: newSurname,
        });
    }

    ChangedDescription = (newDescritiopin: string) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            description: newDescritiopin,
        });
    }

    ChangedCompany = (newCompany: string) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            company: newCompany,
        });
    }

    ChangedOrigin = (newOrigin: string) => {
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            origin: newOrigin,
        });
    }

    ChangedOriginDesc = (newOriginDesc: string) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            originDesc: newOriginDesc,
        });
    }

    ChangedDestination = (newDestination: string) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            destination: newDestination,
        });
    }

    ChangedDestinationDesc = (newDestinationDesc: string) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            destinationDesc: newDestinationDesc,
        });
    }

    ChangedPrice = (newPrice: number) => { 
        this.setState({
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
            price: this.trunc(newPrice, 2),
        });
    }

    trunc(num: number, fixed: number) : number{
        const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
        const result = num.toString().match(re)
        return result ? Number(result[0]) : num
    }

    
    handleEnterPressed = (e: any) => {
        if (e.key === 'Enter' && this.state.verifyCF && this.state.verifyPin) {
            this.verify();
        }
    }

    closeModal = () => {
        this.setState({
            showVerifyModal: false,
            showFinalizeModal: false
        });
    }

    copyVerifyData = () => {
        this.setState({
            finalizeCF: this.state.validationResponse ? this.state.validationResponse.cf_studente : '',
            finalizePin: this.state.validationResponse ? this.state.validationResponse.pin : '',
            name: this.state.validationResponse ? this.state.validationResponse.nome_studente : '',
            surname: this.state.validationResponse ? this.state.validationResponse.cognome_studente : '',
            origin: this.state.validationResponse ? this.state.validationResponse.codice_belfiore_comune_partenza : '',
            destination: this.state.validationResponse ? this.state.validationResponse.codice_belfiore_comune_destinazione : '',
            showVerifyModal: false
        });
    }

    resetFinalizeFields = () => {
        this.setState({
            finalizeCF: '',
            finalizePin: '',
            name: '',
            surname: '',
            description: '',
            company: '',
            origin: '',
            originDesc: '',
            destination: '',
            destinationDesc: '',
            price: -1,
            finalizeError: false,
            finalizeErrorMessage: '',
            finalizeSuccess: false,
        });
    }

    downloadStoredData = () => {
        this.setState({ downloadingData: true },
        () => {
             this.engService.downloadStoredData().then((storedData) => {
             if (storedData) {
                 this.setState({downloadingData: false }, () => { 
                     this.exportToCSV(storedData.data, "resoconto");
                 });
             }
             }).catch((err) => {
                this.setState({downloadingData: false }, () => { 
                    console.log("errore nel download dei dati");
                });
             });
        });
    };

    exportToCSV = (csvData: any, fileName: any) => {
        
        const formattedData: FinalizeRequestBody[] = csvData.map((e: FinalizeRequestBody) => {
            return {
                'Codice Fiscale': e.cf_studente,
                'Pin': e.pin,
                'Nome': e.nome_studente,
                'Cognome': e.cognome_studente,
                'Descrizione': e.descrizione,
                'Azienda': e.azienda,
                'Origine': e.origine_abbonamento,
                'Descrizione Origine': e.desc_origine_abbonamento,
                'Destinazione': e.destinazione_abbonamento,
                'Descrizione destinazione': e.desc_destinazione_abbonamento,
                'Prezzo': e.prezzo_abbonamento
             }
            }
        );
        
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(data, fileName + fileExtension);
        
    
    }

    public render() {
        return (
            <Home
                {...this.state}
                verify={this.verify}
                changedVerifyCF={this.ChangedVerifyCF}
                changedVerifyPin={this.ChangedVerifyPin}
                openFinalizeModal={this.openFinalizeModal}
                finalize={this.finalize}
                changedFinalizeCF={this.ChangedFinalizeCF}
                changedFinalizePin={this.ChangedFinalizePin}
                changedName={this.ChangedName}
                changedSurname={this.ChangedSurname}
                changedDescription={this.ChangedDescription}
                changedCompany={this.ChangedCompany}
                changedOrigin={this.ChangedOrigin}
                changedOriginDesc={this.ChangedOriginDesc}
                changedDestination={this.ChangedDestination}
                changedDestinationDesc={this.ChangedDestinationDesc}
                changedPrice={this.ChangedPrice}
                closeModal={this.closeModal}
                copyVerifyData={this.copyVerifyData}
                resetFinalizeFields={this.resetFinalizeFields}
                downloadHandler={this.downloadStoredData}
                currentUser={this.sessionService.getItem(SessionKey.USERNAME)}
            />
        )
    }

}

export default HomeContainer;