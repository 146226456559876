import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import PrivateRoute from './components/private-route';
import HomeContainer from './containers/home/home-container';
import LoginContainer from './containers/login/login-container';
import ModifyPasswordContainer from './containers/modify_password/modify-password-container';

class App extends React.Component {
  public render() {
    return (
      <div className="main">
        <Router>
          <Switch>
            <Route exact path='/login'>
              <LoginContainer />
            </Route>
            <Route exact path='/modify-password'>
              <ModifyPasswordContainer />
            </Route>
            <PrivateRoute exact path="/home">
              <HomeContainer />
            </PrivateRoute>
            <Redirect exact from="/" to="login" />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
