import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React from 'react';
import loading from '../assets/loader.gif';


type  FinalizeFormProps = {
  finalizing: boolean;
  finalizeError: boolean;
  finalizeErrorMessage: string;
  finalizeSuccess: boolean;
  resetFinalizeFields: () => void;
  openFinalizeModal: () => void;
  changedCF: (CF: string) => void;
  changedPin: (pin: string) => void;
  changedName: (name: string) => void;
  changedSurname: (surname: string) => void;
  changedDescription: (description: string) => void;
  changedCompany: (company: string) => void;
  changedOrigin: (origin: string) => void;
  changedOriginDesc: (originDesc: string) => void;
  changedDestination: (destination: string) => void;
  changedDestinationDesc: (destinationDesc: string) => void;
  changedPrice: (price: number) => void;
  CF: string;
  PIN: string;
  name: string;
  surname: string;
  description: string;
  company: string;
  origin: string;
  originDesc: string;
  destination: string;
  destinationDesc: string;
  price: number;
}

export default class  FinalizeForm extends React.Component<FinalizeFormProps, {}> {

    public render() {
        
        const { 
          openFinalizeModal, 
          changedCF, 
          changedPin, 
          changedName, 
          changedSurname, 
          changedDescription, 
          changedCompany, 
          // changedOrigin,
          changedOriginDesc,
          // changedDestination,
          changedDestinationDesc,
          changedPrice,
          finalizing,
          finalizeError,
          finalizeErrorMessage,
          finalizeSuccess,
          CF,
          PIN,
          name,
          surname,
          description,
          company,
          // origin,
          originDesc,
          // destination,
          destinationDesc,
          price,
          resetFinalizeFields
        } = this.props;

        return (
            <form className="home-area-form area-form">
              <h4 className="form-title">Compila il form per finalizzare un abbonamento</h4>
              <div className="home-field">
                <TextField
                  className="cf-field"
                  id="finalize-cf-field"
                  label="CF"
                  autoComplete="finalize-current-cf"
                  variant="outlined"
                  value= {CF}
                  onChange={(e) => changedCF(e.target.value)}
                  required
                />
              </div>
              <div className="home-field">
                <TextField
                  id="finalize-pin-field"
                  label="PIN"
                  autoComplete="finalize-current-pin"
                  variant="outlined"
                  value= {PIN}
                  onChange={(e) => changedPin(e.target.value)}
                  required
                />
              </div>
              <div className="home-field">
                <TextField
                  id="finalize-name-field"
                  label="Nome"
                  autoComplete="finalize-current-name"
                  variant="outlined"
                  value= {name}
                  onChange={(e) => changedName(e.target.value)}
                />
              </div>
              <div className="home-field">
                <TextField
                  id="finalize-surname-field"
                  label="Cognome"
                  autoComplete="finalize-current-surname"
                  variant="outlined"
                  value= {surname}
                  onChange={(e) => changedSurname(e.target.value)}
                />
              </div>
              <div className="home-field">
                <TextField
                  id="finalize-description-field"
                  label="Descrizione"
                  autoComplete="finalize-current-description"
                  variant="outlined"
                  value={description}
                  onChange={(e) => changedDescription(e.target.value)}
                />
              </div>
              <div className="home-field">
                <TextField
                  id="finalize-company-field"
                  label="Azienda"
                  autoComplete="finalize-current-company"
                  variant="outlined"
                  value={company}
                  onChange={(e) => changedCompany(e.target.value)}
                />
              </div>
              {
              /*
              <div className="home-field">
                <TextField
                  id="finalize-origin-field"
                  label="Origine"
                  autoComplete="finalize-current-origin"
                  variant="outlined"
                  value= {origin}
                  onChange={(e) => changedOrigin(e.target.value)}
                  required/>
              </div>
              */
              }
              <div className="home-field">
                <TextField
                  id="finalize-origin_desc-field"
                  label="Descrizione origine"
                  autoComplete="finalize-current-origin_desc"
                  variant="outlined"
                  value={originDesc}
                  onChange={(e) => changedOriginDesc(e.target.value)}
                />
              </div>
              {
                /*
                <div className="home-field">
                  <TextField
                    id="finalize-destination-field"
                    label="Destinazione"
                    autoComplete="finalize-current-destination"
                    variant="outlined"
                    value={destination}
                    onChange={(e) => changedDestination(e.target.value)}
                    required
                  />
                </div>
                */ 
              }
              <div className="home-field">
                <TextField
                  id="finalize-destination_desc-field"
                  label="Descrizione dest..."
                  autoComplete="finalize-current-destination_desc"
                  variant="outlined"
                  value={destinationDesc}
                  onChange={(e) => changedDestinationDesc(e.target.value)}
                />
              </div>
              <div className="home-field">
                <TextField
                  id="finalize-price-field"
                  label="Prezzo abbonamento"
                  autoComplete="finalize-price"
                  variant="outlined"
                  value={price > 0 && price}
                  type="number"
                  onChange={(e) => changedPrice(Number(e.target.value))}
                  required
                />
              </div>
              {finalizeError && <h5 className="finalize-error-message finalize-result">{finalizeErrorMessage}</h5>}
              {finalizeSuccess && <h5 className="finalize-success-message finalize-result">Abbonamento correttamente finalizzato!</h5>}
              <div className="home-field home-button-area">
                <Button className="reset-button" variant="contained" color="primary" onClick={resetFinalizeFields}>
                  reset
                </Button>
                <Button variant="contained" color="primary" onClick={openFinalizeModal}>
                  finalizza
                </Button>
                {finalizing &&
                  <div className="overlay">
                    <img className='loading-gif' src={loading} alt='loadingGif' />
                  </div>
                }
              </div>
            </form>
        )
    }
}
