import * as React from "react";
import { Redirect } from 'react-router-dom';
import { AuthService } from '../../services/auth-service';
import loginCredentialService from '../../services/profile.service';
import { SessionKey, SessionStorageService } from "../../services/session-storage.service";
import Login from "./login";

interface LoginContainerState {
  username: string;
  password: string;
  verifingAuthorization: boolean;
  authError: boolean;
  authErrorMessage: string;
  redirectToHome: boolean;
  redirectToModifyPassword: boolean;
}



class LoginContainer extends React.Component<{}, LoginContainerState> {

  private loginService: AuthService = new AuthService();
  private sessionService: SessionStorageService = new SessionStorageService();

  constructor(props: any) {
    super(props);
    this.state = {
      username: '',
      password: '',
      verifingAuthorization: false,
      authError: false,
      authErrorMessage: '',
      redirectToHome: false,
      redirectToModifyPassword: false
    }
  }

  componentDidMount() {
    document.addEventListener('keypress', this.handleEnterPressed);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleEnterPressed);
  }

  setRedirectToHome = () => {
    this.setState({
      redirectToHome: true
    })
  }
  
  setRedirectToModifyPassword = () => {
    this.setState({
      redirectToModifyPassword: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirectToHome) {
      return <Redirect to='/home' />
    }
    if (this.state.redirectToModifyPassword){
      return <Redirect to='/modify-password' />
    }
  }


  login = () => {
    const { username, password } = this.state;  
    this.sessionService.setItem(SessionKey.USERNAME, username);
    this.setState({ verifingAuthorization: true },
      () => {
        this.loginService.validateLoginData(username, password).then((loginResponse) => {
          if (loginResponse) {
            loginCredentialService.setValue(loginResponse.data.idToken);
            this.sessionService.setItem(SessionKey.ID_TOKEN, loginResponse.data.idToken);
            this.sessionService.setItem(SessionKey.REFRESH_TOKEN, loginResponse.data.refreshToken)
            this.setRedirectToHome();
          }
        }).catch((e) => {
          if(e.response && e.response.data.message === "New password is required."){
            this.setState({ authError: true, verifingAuthorization: false}, () => {
              this.setRedirectToModifyPassword();
            });
          } else if(e.message.includes('Network Error')){
            this.setState({ authError: true, verifingAuthorization: false, authErrorMessage: 'errore di connessione al server' });
          } else
            this.setState({ authError: true, verifingAuthorization: false, authErrorMessage: 'username o password errati' });
          });
      });

  };

  changedUsername = (newUsername: string) => {
    this.setState({
      username: newUsername,
      authError: false
    });
  }

  changedPassword = (newPassword: string) => {
    this.setState({
      password: newPassword,
      authError: false
    });
  }

  handleEnterPressed = (e: any) => {
    if (e.key === 'Enter' && this.state.username && this.state.password) {
      this.login();
    }
  }

  public render() {
    return (
      <>
        {this.renderRedirect()}
        <Login
          {...this.state}
          login={this.login}
          changedUsername={this.changedUsername}
          changedPassword={this.changedPassword}        
        />
      </>
    );
  }
}

export default LoginContainer;
