import * as React from 'react';
import Button from "@material-ui/core/Button";
import FinalizeForm from '../../components/finalize-form';
import Modal from '../../components/modal/modal';
import VerifyForm from '../../components/verify-form';
import { VerifyResponseOk } from '../../models/eng.model';
import loading from '../../assets/loader.gif';

import './home.scss';

interface HomeProps {
  verifingValidity: boolean;
  verificationError: boolean;
  verificationErrorMessage: string;
  verify: () => void;
  changedVerifyCF: (CF: string) => void;
  changedVerifyPin: (pin: string) => void;
  validationResponse?: VerifyResponseOk;
  finalizing: boolean;
  finalizeError: boolean;
  finalizeErrorMessage: string;
  finalizeSuccess: boolean;
  openFinalizeModal: () => void;
  finalize: () => void;
  resetFinalizeFields: () => void;
  changedFinalizeCF: (CF: string) => void;
  changedFinalizePin: (pin: string) => void;
  changedName: (name: string) => void;
  changedSurname: (surname: string) => void;
  changedDescription: (description: string) => void;
  changedCompany: (company: string) => void;
  changedOrigin: (origin: string) => void;
  changedOriginDesc: (originDesc: string) => void;
  changedDestination: (destination: string) => void;
  changedDestinationDesc: (destinationDesc: string) => void;
  changedPrice: (price: number) => void;
  showVerifyModal: boolean;
  showFinalizeModal: boolean;
  closeModal: () => void;
  copyVerifyData: () => void;
  finalizeCF: string;
  finalizePin: string;
  name: string;
  surname: string;
  description: string;
  company: string;
  origin: string;
  originDesc: string;
  destination: string;
  destinationDesc: string;
  price: number;
  downloadingData: boolean;
  downloadHandler: () => void;
  currentUser: string;
}

class Home extends React.Component<HomeProps>{

    public render() {
        const { 
          verify, 
          changedVerifyCF, 
          changedVerifyPin, 
          verificationError,
          verificationErrorMessage, 
          verifingValidity,
          validationResponse,
          finalizing,
          finalizeError,
          finalizeErrorMessage,
          finalizeSuccess,
          finalize,
          resetFinalizeFields,
          openFinalizeModal,
          changedFinalizeCF,
          changedFinalizePin,
          changedName,
          changedSurname,
          changedDescription,
          changedCompany,
          changedOrigin,
          changedOriginDesc,
          changedDestination,
          changedDestinationDesc,
          changedPrice,
          showVerifyModal,
          showFinalizeModal,
          closeModal,
          copyVerifyData,
          finalizeCF,
          finalizePin,
          name,
          surname,
          description,
          company,
          origin,
          originDesc,
          destination,
          destinationDesc,
          price,
          downloadingData,
          downloadHandler,
          currentUser
        } = this.props;

        return (
          <div className="home-content">
            <div className="left-container">
              <VerifyForm 
                verificationError={verificationError}
                verifingValidity={verifingValidity}
                verificationErrorMessage={verificationErrorMessage}
                verify={verify}
                changedCF={changedVerifyCF}
                changedPin={changedVerifyPin}
              />
              {
                 ( 
                  currentUser === 'missiroli@cooptrasportiriolo.it' ||
                  currentUser === 'info@cooptrasportiriolo.it' || 
                  currentUser === 'silvia.costa@coerbus.it' ||
                  currentUser === 'biglietteria@zaganellibus.com' ||
                  currentUser === 'bagnara@ricci-bus.it'
                ) && 
                <Button className="download-excel-button" variant="contained" color="primary" onClick={downloadHandler}>
                    Scarica resoconto excel
                </Button>
              }
            </div>
            <div className="right-container">
              <FinalizeForm
                finalizing={finalizing}
                finalizeError={finalizeError}
                finalizeErrorMessage={finalizeErrorMessage}
                finalizeSuccess={finalizeSuccess}
                openFinalizeModal={openFinalizeModal}
                changedCF={changedFinalizeCF}
                changedPin={changedFinalizePin}
                changedName={changedName}
                changedSurname={changedSurname}
                changedDescription={changedDescription}
                changedOrigin={changedOrigin}
                changedOriginDesc={changedOriginDesc}
                changedCompany={changedCompany}
                changedDestination={changedDestination}
                changedDestinationDesc={changedDestinationDesc}
                changedPrice={changedPrice}
                resetFinalizeFields={resetFinalizeFields}
                CF={finalizeCF}
                PIN={finalizePin}
                name={name}
                surname={surname}
                description={description}
                company={company}
                origin={origin}
                originDesc={originDesc}
                destination={destination}
                destinationDesc={destinationDesc}
                price={price}
              /> 
              { 
                ( 
                  currentUser === 'missiroli@cooptrasportiriolo.it' ||
                  currentUser === 'info@cooptrasportiriolo.it' || 
                  currentUser === 'silvia.costa@coerbus.it' ||
                  currentUser === 'biglietteria@zaganellibus.com' ||
                  currentUser === 'bagnara@ricci-bus.it'
                ) && 
              <Button className="download-excel-button" variant="contained" color="primary" onClick={downloadHandler}>
                  Scarica resoconto excel
              </Button>}
            </div>
            
            { downloadingData &&
                <div className="overlay">
                  <img className='loading-gif' src={loading} alt='loadingGif' />
              </div>                
            }

            <Modal show={showVerifyModal} handleClose={closeModal} secondaryButton={true} secondaryButtonText="copia dati" secondaryButtonHandler={copyVerifyData}>
              <div className= "verify-modal-data">
                <h3>La verifica è andata a buon fine</h3>
                <h5>{validationResponse?.cognome_studente} {validationResponse?.nome_studente}</h5>
                <h5>CF: {validationResponse?.cf_studente}</h5>
                <h5>PIN: {validationResponse?.pin}</h5>
                {/*
                  <h5>Codice comune partenza: {validationResponse?.codice_belfiore_comune_partenza}</h5>
                  <h5>Codice comune destinazione: {validationResponse?.codice_belfiore_comune_destinazione}</h5>*/
                }
              </div>
            </Modal>

            <Modal show={showFinalizeModal} handleClose={closeModal} secondaryButton={true} secondaryButtonText="Conferma e finalizza" secondaryButtonHandler={finalize}>
              <div className= "verify-modal-data">
                <h3 className= "confirm-finalize-message">Sei sicuro di voler finalizzare? l'operazione non è reversibile</h3>
                <h5>{validationResponse?.cognome_studente} {validationResponse?.nome_studente}</h5>
                <h5>CF: {finalizeCF}</h5>
                <h5>PIN: {finalizePin}</h5>
                <h5>Nome: {name}</h5>
                <h5>Cognome: {surname}</h5>
                {
                  //<h5>Origine: {origin}</h5>
                }
                <h5>Descrizione Origine: {originDesc}</h5>
                {
                  // <h5>Destinazione: {destination}</h5>
                }
                <h5>Descrizione Destinazione: {destinationDesc}</h5>
                <h5>Prezzo Abbonamento: {price > 0 && price}</h5>
              </div>
            </Modal>

        </div>
        )
    }

}

export default Home;