import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React from 'react';
import loading from '../assets/loader.gif';


type  VerifyFormProps = {
  verifingValidity: boolean;
  verificationError: boolean;
  verificationErrorMessage: string;
  verify: () => void;
  changedCF: (CF: string) => void;
  changedPin: (pin: string) => void;
}

export default class  VerifyForm extends React.Component< VerifyFormProps, {}> {

    public render() {
        
        const { verify, changedCF, changedPin, verificationError, verificationErrorMessage, verifingValidity } = this.props;

        return (
            <form className="home-area-form area-form">
              <h4 className="form-title">Compila il form sottostante per verificare la validità di CF e PIN</h4>
              <div className="home-field">
                <TextField
                  className="cf-field"
                  error={verificationError}
                  helperText=""
                  id="verify-cf-field"
                  label="CF"
                  autoComplete="verify-current-cf"
                  variant="outlined"
                  onChange={(e) => changedCF(e.target.value)}
                  required
                />
              </div>
              <div className="home-field">
                <TextField
                  error={verificationError}
                  helperText={verificationError && verificationErrorMessage}
                  id="verify-pin-field"
                  label="PIN"
                  autoComplete="verify-current-pin"
                  variant="outlined"
                  onChange={(e) => changedPin(e.target.value)}
                  required
                />
              </div>
              <div className="home-field home-button-area">
                <Button variant="contained" color="primary" onClick={verify}>
                  verifica
                </Button>
                {verifingValidity &&
                  <div className="overlay">
                    <img className='loading-gif' src={loading} alt='loadingGif' />
                  </div>                
                  }
              </div>
            </form>
        )
    }
}
