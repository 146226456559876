export enum SessionKey {
    USERNAME = 'Username',
    ID_TOKEN = 'User',
    REFRESH_TOKEN = 'Auth'
}

export class SessionStorageService {

    setItem(key: SessionKey, value: string) {
        sessionStorage.setItem(key, value);
    }

    getItem(key: SessionKey): string {
        const value = sessionStorage.getItem(key);
        return value ? value : '';
    }

    removeItem(key: SessionKey) {
        sessionStorage.removeItem(key);
    }
}