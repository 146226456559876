import * as React from "react";
import { Redirect } from 'react-router-dom';
import { AuthService } from '../../services/auth-service';
import loginCredentialService from '../../services/profile.service';
import { SessionKey, SessionStorageService } from "../../services/session-storage.service";
import ModifyPassword from "./modify-password";

interface LoginContainerState {
  oldPassword: string;
  newPassword: string;
  repeatedNewPassword: string;
  verifingAuthorization: boolean;
  authError: boolean;
  authErrorMessage: string;
  redirect: boolean;
  changePassword: boolean;
  modifyPasswordError: boolean;
  modifyPasswordErrorMessage: string;
}



class ModifyPasswordContainer extends React.Component<{}, LoginContainerState> {

  private loginService: AuthService = new AuthService();
  private sessionService: SessionStorageService = new SessionStorageService();

  constructor(props: any) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      repeatedNewPassword: '',
      verifingAuthorization: false,
      authError: false,
      authErrorMessage: '',
      redirect: false,
      changePassword: false,
      modifyPasswordError: false,
      modifyPasswordErrorMessage: ''
    }
  }

  componentDidMount() {
    document.addEventListener('keypress', this.handleEnterPressed);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleEnterPressed);
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }
  
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/home' />
    }
  }

  modifyPassword = () => {
    const { oldPassword, newPassword, repeatedNewPassword } = this.state;
    const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g
    if(oldPassword.length <= 0){
      this.setState({
        modifyPasswordError: true,
        modifyPasswordErrorMessage: 'Inserire la vecchia password'
      })
    } else if(newPassword !== repeatedNewPassword){
      this.setState({
        modifyPasswordError: true,
        modifyPasswordErrorMessage: 'Le password non corrispondono'
      })
    } else if(newPassword.length < 8 || !regex.test(newPassword)){
      this.setState({
        modifyPasswordError: true,
        modifyPasswordErrorMessage: 'La password deve avere 8 caratteri 1 maiuscola, una minuscola e un numero'
      })
    } else {
      this.setState({ verifingAuthorization: true },
        () => {
          this.loginService.changePassword(oldPassword, newPassword).then((modifyPasswordResponse) => {
            if (modifyPasswordResponse) {
              loginCredentialService.setValue(modifyPasswordResponse.data.idToken);
              this.sessionService.setItem(SessionKey.ID_TOKEN, modifyPasswordResponse.data.idToken);
              this.sessionService.setItem(SessionKey.REFRESH_TOKEN, modifyPasswordResponse.data.refreshToken)
              this.setRedirect();
            }
          }).catch((e) => {
            if(e.response && e.response.data.message === "Incorrect username or password."){
              this.setState({ modifyPasswordError: true, verifingAuthorization: false, modifyPasswordErrorMessage: 'la vecchia password non è valida' });
            } else if(e.message.includes('Network Error')){
              this.setState({ modifyPasswordError: true, verifingAuthorization: false, modifyPasswordErrorMessage: 'errore di connessione al server' });
            } else {
              this.setState({ modifyPasswordError: true, verifingAuthorization: false, modifyPasswordErrorMessage: 'errore nella modifica della password' });
            }
          });
        });
    }
  };

  changedOldPassword = (password: string) => {
    this.setState({
      oldPassword: password,
      modifyPasswordError: false,
      modifyPasswordErrorMessage: ''
    });
  }

  changedNewPassword = (password: string) => {
    this.setState({
      newPassword: password,
      modifyPasswordError: false,
      modifyPasswordErrorMessage: ''
    });
  }

  changedRepeatedNewPassword = (password: string) => {
    this.setState({
      repeatedNewPassword: password,
      modifyPasswordError: false,
      modifyPasswordErrorMessage: ''
    });
  }

  handleEnterPressed = (e: any) => {
    if (e.key === 'Enter' && this.state.oldPassword && this.state.newPassword && this.state.repeatedNewPassword) {
      this.modifyPassword();
    }
  }

  public render() {
    return (
      <>
        {this.renderRedirect()}
        <ModifyPassword
          {...this.state}    
          changedOldPassword={this.changedOldPassword}         
          changedNewPassword={this.changedNewPassword}
          changedRepeatedNewPassword={this.changedRepeatedNewPassword}
          modifyPassword={this.modifyPassword}
        />
      </>
    );
  }
}

export default ModifyPasswordContainer;
